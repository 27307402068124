import React, { useMemo, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownToggle,
  Form,
  Dropdown,
  CardBody,
} from "reactstrap";
import {
  Bank_Available,
  Bank_Transaction_Get,
  Mobile_Number_Update,
  Payment_Update,
  Transaction_Update,
  Used_Mark,
} from "../../../redux/slices/bankTransactionSlice";
import UpdateTransactionModal from "../../../components/Custom/Modals/UpdateTransactionModal/UpdateTransactionModal";
import ManualBankEntryModel from "../../../components/Custom/Modals/ManualBankEntryModel/ManualBankEntryModel";
import UpdatePaymentModal from "../../../components/Custom/Modals/UpdatePaymentModal/UpdatePaymentModal";
import MarkAsUsedModal from "../../../components/Custom/Modals/MarkAsUsedModal/MarkAsUsedModal";
import MergeUTRModal from "../../../components/Custom/Modals/MergeUTRModal/MergeUTRModal";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { setTransactionFilter } from "../../../redux/slices/transactionSlice";
import { Table, TableRow } from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  DROPDOWN_ALL_VALUE,
  DROPDOWN_SEARCH_VALUE,
  showToaster,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import { useForm } from "react-hook-form";
import "./BankTransaction.scss";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import GenerateReportModal from "../../../components/Custom/Modals/GenerateReportModal/GenerateReportModal";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { useRole } from "../../../redux/slices/authSlice";

const MobileCell = (data) => {
  const [mobileText, setMobileText] = useState();

  useEffect(() => {
    setMobileText(data?.mobile_number);
  }, [data]);

  return (
    <>
      {data?.id === data?.mobileId ? (
        <div className="d-flex  tableinput">
          <InputField
            type="number"
            placeholder="Enter Mobile Number"
            value={mobileText}
            onChange={(e) => {
              setMobileText(e.target.value);
            }}
          />
          <Button
            color="primary"
            className="btn-smallsize"
            onClick={async () => {
              await data?.onUpdateMobileNumber({
                pk: data?.id,
                id: data?.id,
                value: +mobileText,
              });
              setMobileText();
              data?.setMobileId();
            }}
          >
            <i className="fa-solid fa-check"></i>
          </Button>
          <Button
            color="danger"
            className="btn-smallsize"
            onClick={async () => {
              setMobileText();
              data?.setMobileId();
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </Button>
        </div>
      ) : (
        <span
          onClick={(e) => {
            setMobileText(+data?.mobile_number);
            data?.setMobileId(data?.id);
          }}
          style={{
            color: "var(--chatlook-darkblue--)",
          }}
          className="cursor-pointer"
        >
          {data.mobile_number ? data.mobile_number : "No Set"}
        </span>
      )}
    </>
  );
};

const searchDataOptions = [
  { value: "amount", label: "Amount" },
  { value: "acNumber", label: "Account Number" },
  { value: "UTR", label: "UTR" },
  { value: "upiId", label: "UPI Id" },
];

const IsGetOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

const initialValues = {
  isget: DROPDOWN_ALL_VALUE,
  bankName: DROPDOWN_ALL_VALUE,
  filter: DROPDOWN_SEARCH_VALUE,
  search: "",
  lower_amount: "",
  upper_amount: "",
  date: [null, null],
};

const BankTransaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { bankTransactionState, availableBankState } = useSelector(
    (state) => state.bankTransaction
  );
  const roleGenerate = useRole("Generate Report");

  const [refresh, setRefresh] = useState(false);
  const [isManualBankEntryModel, setIsManualBankEntryModel] = useState(false);
  const [mergeUtr, setMergeUtr] = useState(false);
  const [isMarkOpen, setIsMarkOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isTransactionOpen, setIsTransactionOpen] = useState(false);
  const [filter, setFilter] = useState({ ...TRA_FILTER, isToast: false });
  const [filterColumns, setFilterColumns] = useState([]);
  const [mobileId, setMobileId] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const role = useRole("Bank Transaction");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [isGenerateReportModal, setIsGenerateReportModal] = useState(false);

  const onCloseGenerateRepostModal = () => {
    setIsGenerateReportModal(!isGenerateReportModal);
  };

  const columns = [
    {
      title: "DATE",
      name: "createdAt",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <div>
                  <span>Create : </span>
                  {data?.createdAt || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  <span>Transaction Date : </span>
                  {data?.bankTxnDate || ""}
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      name: "amount",
      Cell: (data) => {
        return <span>{seperator(data?.amount)}</span>;
      },
    },
    {
      title: "DETAILS",
      name: "mode",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <div>
                  <span>Transaction Mode : </span>
                  {data?.txnMode || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  <span>Payment Mode : </span>
                  {data?.paymentMode || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  <span>UPI ID : </span>
                  {data?.upiId || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  <span>UTR : </span>
                  <span
                    style={{
                      color: "var(--chatlook-darkblue--)",
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(
                        setTransactionFilter({
                          filter: "bankRrn",
                          search: data?.UTR,
                        })
                      );
                      navigate("/transaction");
                    }}
                  >
                    {data?.UTR || ""}
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "MOBILE NO	",
      name: "mobile_number",
      isOpen: false,
      Cell: (data) => {
        return (
          <div>
            <MobileCell
              {...data}
              mobileId={mobileId}
              setMobileId={setMobileId}
              onUpdateMobileNumber={async (payload) => {
                await onUpdateMobileNumber(payload);
                await get_Bank_Transaction(true);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "PG ID	",
      name: "txnId",
    },
    {
      title: "DESC",
      name: "description",
      type: "ellipsis",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <div>{data?.description || ""}</div>
              </div>
              <div className="order-singal-div">
                <div>
                  <span>IS Get : </span>
                  {data.isget ? "Yes" : "No"}
                </div>
              </div>
            </div>

            <div style={{ position: "absolute", bottom: "8px" }}>
              {!data?.isget ? (
                <>
                  <div className="d-flex gap-1">
                    {role.includes("bank-transaction-mark-as-used") ? <Button
                      className=" filterbtn btn-smallsize"
                      onClick={() => {
                        setIsMarkOpen(data?.UTR);
                      }}
                    >
                      <span>Mark As Used</span>
                    </Button> : null}
                    {role.includes("bank-transaction-update-ref-id") ? <Button
                      className="filterbtn btn-smallsize"
                      onClick={() => {
                        setIsPaymentOpen(data?.UTR);
                      }}
                    >
                      <span>Update Ref ID</span>
                    </Button> : null}

                    {role.includes("bank-transaction-update-transaction-id") ? <Button
                      className="filterbtn btn-smallsize"
                      onClick={() => {
                        setIsTransactionOpen(data?.UTR);
                      }}
                    >
                      <span>Update Tran Id</span>
                    </Button> : null}
                  </div>
                </>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "BANK DETAILS",
      name: "acName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <div>
                  <span>Name : </span>
                  {data?.acName || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  <span>A/C No : </span>
                  {data?.acNumber || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  <span>UTR : </span>
                  {data?.UTR || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  <span>IFSC : </span>
                  {data?.ifscCode || ""}
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const onCloseModal = () => {
    setIsManualBankEntryModel(!isManualBankEntryModel);
  };
  const onUtrCloseModal = () => {
    setMergeUtr(!mergeUtr);
  };
  const data = useMemo(() => {
    return bankTransactionState?.data?.length ? bankTransactionState.data : [];
  }, [bankTransactionState?.data]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
      isToast: true,
    });
  };

  const onMarkAsUsed = async () => {
    try {
      setIsButtonLoading(true);
      const payload = { paymentUtr: isMarkOpen };
      const res = await dispatch(Used_Mark(payload)).unwrap();
      responseToaster(res);
      setIsMarkOpen(false);
      setFilter({ ...filter, isToast: false });
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onUpdatePayment = async (payload) => {
    try {
      setIsButtonLoading(true);
      const payupdate = await dispatch(Payment_Update(payload)).unwrap();
      responseToaster(payupdate);
      setIsPaymentOpen(false);
      setFilter({ ...filter, isToast: false });
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onUpdateTransaction = async (payload) => {
    try {
      setIsButtonLoading(true);
      const res = await dispatch(Transaction_Update(payload)).unwrap();
      responseToaster(res);
      setIsTransactionOpen(false);
      setFilter({ ...filter, isToast: false });
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onUpdateMobileNumber = async (payload) => {
    try {
      const res = await dispatch(Mobile_Number_Update(payload)).unwrap();
      responseToaster(res);
    } catch (err) { }
  };

  const preparePayload = (filter) => {
    const {
      isFilter,
      page_no,
      limit,
      filter: dropdown,
      search,
      date,
      ...rest
    } = filter;
    const payload = isFilter
      ? {
        filter_data: {
          [dropdown]: search,
          startDate: dateFormatter(date?.[0], "start"),
          endDate: dateFormatter(date?.[1], "end", date?.[0]),
          ...rest,
        },
        page_no,
        limit,
      }
      : {
        page_no,
        limit,
        filter_data: {
          startDate: dateFormatter(new Date(), "start"),
          endDate: dateFormatter(new Date(), "end"),
        },
      };

    return payload;
  };

  const get_Bank_Transaction = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }

      const payload = preparePayload(filter);
      const res = await dispatch(Bank_Transaction_Get(payload)).unwrap();
      if (filter?.isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("bankTransaction-list")) {
      get_Bank_Transaction(true);
    }
  }, [filter]);

  useEffect(() => {
    if (role.includes("bankTransaction-list")) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          get_Bank_Transaction(true);
        }
      }, 9000);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh, filter]);

  useEffect(() => {
    const newColumns = columns
      ?.filter((item) => item?.isOpen !== false)
      ?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const { control, handleSubmit, getValues, reset, watch } = useForm({
    defaultValues: initialValues,
  });

  const getBanklistOptions = () => {
    const opttions = availableBankState?.bankList
      ?.slice()
      ?.sort((a, b) => a?.bank_name?.localeCompare(b?.bank_name))
      ?.map((val) => ({
        value: val?.account_number,
        label: `${val?.bank_name} - ${val?.account_holder_name} ${val?.account_number}`,
      }));
    return opttions;
  };

  const getDRBanklistOptions = () => {
    const opttions = bankTransactionState?.dr_active_bank
      ?.slice()
      ?.sort((a, b) => a?.bank_name?.localeCompare(b?.bank_name))
      ?.map((val) => ({
        value: val?.dr_bank_id,
        label: `${val?.account_holder_name} - (${val?.account_number}) (${val?.bank_name})`,
      }));
    return opttions;
  };

  const bank_Available = async () => {
    try {
      const payload = {};
      await dispatch(Bank_Available(payload)).unwrap();
    } catch (err) { }
  };

  const handleBankBalClick = () => {
    try {
      if (watch("bankName") === DROPDOWN_ALL_VALUE) {
        showToaster("Please select bank account", "Error");
      } else {
        const av_bank_id = availableBankState?.bankList?.find(
          (item) => item?.account_number === watch("bankName")
        )?.av_bank_id;
        navigate(`/bank-transaction/bal/sheet/${av_bank_id}`);
      }
    } catch (err) { }
  };

  useEffect(() => {
    bank_Available();
  }, []);

  if (!role.includes("bankTransaction-list")) {
    return;
  }

  return (
    <>
      <div className={`${isLoading && refresh ? "opacity-25" : ""}`}>
        <Card>
          <CardHeader className="align-items-start">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h4 className="main-title">
                <b>Bank Transaction</b>
              </h4>
              <div className="d-flex" style={{ gap: "10px" }}>
                <span className=" aurorefresh-btn">
                  <Button
                    type="button"
                    className={`${refresh ? "" : "filterbtn"} gap`}
                    color={refresh ? "primary" : ""}
                    onClick={() => setRefresh(!refresh)}
                    title="Auto refresh"
                  >
                    {refresh ? (
                      <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                    ) : (
                      <i className="fa-solid fa-arrows-rotate"></i>
                    )}
                    Auto Refresh
                  </Button>
                </span>
                {
                  roleGenerate.includes("generate-bank-transaction-report") ? (
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => onCloseGenerateRepostModal()}
                      title="Generate Report"
                    >
                      Generate Report
                    </Button>
                  ) : null}


                <Button
                  type="button"
                  color="primary"
                  onClick={() => handleBankBalClick()}
                  title="Redirect To Bank Bal Sheet"
                >
                  Redirect To Bank Bal Sheet
                </Button>
                {role.includes("bank-transaction-merge-utr") ? <Button
                  type="button"
                  color="primary"
                  onClick={() => setMergeUtr(true)}
                  title="Merge UTR"
                >
                  Merge UTR
                </Button> : null}
                {role.includes("bank-transaction-add") ? <Button
                  type="button"
                  color="primary"
                  onClick={() => setIsManualBankEntryModel(true)}
                  title="Add Manual Bank Entry"
                >
                  Add Manual Bank Entry
                </Button> : null}

                <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                    id="page-header-user-dropdown"
                    tag="button"
                    title="Column Filter"
                  >
                    <IoFilterOutline fill="#7367F0" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="z-3"
                    style={{
                      whiteSpace: "nowrap",
                      padding: 20,
                      transform: "translate(-20px, 22px)",
                    }}
                  >
                    <li className="z-3 text-dark">
                      <span>
                        <i className="fa-solid fa-filter"></i> Filter Column
                      </span>
                    </li>
                    <div className="border-bottom border-light mt-2">
                      {columns?.map((column) => {
                        return (
                          <div className="d-flex justify-content-between">
                            <p>{column?.title}</p>
                            <Switch
                              checked={filterColumns?.includes(column?.name)}
                              size="small"
                              onChange={(e) => {
                                const newData = [...filterColumns];
                                const index = newData?.indexOf(column?.name);
                                if (index > -1) {
                                  newData.splice(index, 1);
                                } else {
                                  newData.push(column?.name);
                                }
                                setFilterColumns(newData);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Form
              className="banktransaction-main-div"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                className="d-flex mt-1 right-content align-items-end button-space "
                style={{ flexWrap: "wrap" }}
              >
                <ControlledDropDown
                  name="filter"
                  label="Filter"
                  isDefaultOption={DROPDOWN_SEARCH_VALUE}
                  options={searchDataOptions}
                  control={control}
                />
                <ControlledInput
                  placeholder="Enter Search Value"
                  name="search"
                  label="Search"
                  control={control}
                />
                <ControlledDropDown
                  label="Is Get"
                  id="type"
                  name="isget"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={IsGetOptions}
                  control={control}
                />
                <ControlledDropDown
                  label="Bank"
                  id="type"
                  name="bankName"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={getBanklistOptions()}
                  control={control}
                />
                <ControlledDatePicker
                  name="date"
                  placeholder="Select Date"
                  label="Date"
                  maxDate={new Date()}
                  selectsRange
                  control={control}
                />
                <ControlledInput
                  type="number"
                  name="lower_amount"
                  label="Min Amount"
                  placeholder="Enter Min Amount"
                  control={control}
                />
                <ControlledInput
                  name="upper_amount"
                  type="number"
                  placeholder="Enter Max Amount"
                  label="Max Amount"
                  control={control}
                />

                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger "
                  onClick={() => {
                    reset();
                    setFilter({ ...TRA_FILTER, isToast: false });
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Table
            columns={columns}
            filterColumns={filterColumns}
            isLoading={isLoading && !refresh}
            data={data}
            isData={!!data?.length}
            count={bankTransactionState?.total_item || 0}
            pagination={filter}
            isExpandable={false}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isToast: false,
              });
            }}
            onColumnsChange={(columns) => {
              setFilterColumns(columns);
            }}
            paginationOptions={TRA_PAGINATION_OPTIONS}
          >
            {data?.map((item) => {
              return (
                <TableRow
                  tdClassName="position-relative btncell-height"
                  item={item}
                  columns={columns}
                  isExpandable={false}
                  filterColumns={filterColumns}
                />
              );
            })}
          </Table>
        </Card>
      </div>
      <ManualBankEntryModel
        isManualBankEntryModel={isManualBankEntryModel}
        DRList={getDRBanklistOptions()}
        onCloseModal={() => onCloseModal()}
        onGet={() => setFilter({ ...filter, isToast: false })}
      />
      <MergeUTRModal
        mergeUtr={mergeUtr}
        onCloseModal={() => onUtrCloseModal()}
        onGet={() => setFilter({ ...filter, isToast: false })}
      />
      <MarkAsUsedModal
        isButtonLoading={isButtonLoading}
        id={isMarkOpen}
        onCancelClick={() => setIsMarkOpen(false)}
        onDoneClick={async () => {
          await onMarkAsUsed();
        }}
      />
      <UpdatePaymentModal
        isButtonLoading={isButtonLoading}
        isOpen={isPaymentOpen}
        onCancelClick={() => setIsPaymentOpen(false)}
        onDoneClick={(value) => {
          onUpdatePayment({
            utr_no_id: isPaymentOpen,
            payment_ref_id: value,
          });
        }}
      />
      <UpdateTransactionModal
        isButtonLoading={isButtonLoading}
        isOpen={isTransactionOpen}
        onCancelClick={() => setIsTransactionOpen(false)}
        onDoneClick={(value) => {
          onUpdateTransaction({
            utr_no_id: isTransactionOpen,
            transaction_id: value,
          });
        }}
      />
      <GenerateReportModal
        isGenerateReportModal={isGenerateReportModal}
        onCloseGenerateRepostModal={onCloseGenerateRepostModal}
        payload={preparePayload(filter)}
      />
    </>
  );
};

export default BankTransaction;
