import React, { useState } from "react";
import { Button, Form, Label, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import Uploadicon from "../../../../assets/images/pages/upload-cloud.png";
import { responseToaster } from "../../../../helperFunctions";
import {
  merchantupload,
  useStatement,
} from "../../../../redux/slices/bankStatementSlice";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import { DROPDOWN_SELECT_VALUE } from "../../../../constant";

const BankStatementFileUplod = (props) => {
  const {
    onCloseModalBankStatementFileUplod,
    BankStatementFileUplodModal,
    onGet,
  } = props;
  const dispatch = useDispatch();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const initialValues = {
    acNumber: DROPDOWN_SELECT_VALUE,
    acFile: "",
  };

  const bankstatementState = useStatement();

  const prepareBankDataOption = () => {
    const options = bankstatementState?.bank
      ?.slice()
      ?.sort((a, b) => a?.label?.localeCompare(b?.label))
      ?.map((val) => ({
        value: `${val?.account_number}*${val?.label}`,
        label: `${val?.account_number} - ${val?.label}`,
      }));
    return options;
  };

  const onSubmit = async () => {
    const values = getValues();
    const fileUpload = new FormData();
    fileUpload.append("acNumber", values?.acNumber);
    fileUpload.append("acFile", values?.acFile?.[0]);
    try {
      setIsButtonLoading(true);
      const response = await dispatch(merchantupload(fileUpload)).unwrap();
      await onGet();
      responseToaster(response);
      onClose();
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const { control, setValue, getValues, handleSubmit, reset, watch } = useForm({
    defaultValues: initialValues,
  });

  const onClose = () => {
    onCloseModalBankStatementFileUplod();
    reset();
  };

  return (
    <Modal
      isOpen={BankStatementFileUplodModal}
      toggle={onClose}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Bank Statement</h3>
        <i onClick={onClose} className="fa-solid fa-xmark"></i>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div>
            <ControlledDropDown
              name="acNumber"
              label="Account Number"
              placeholder="Select Account Number"
              isDefaultOption={false}
              options={prepareBankDataOption()}
              control={control}
            />
            <div className="select-div mt-1">
              <Label>Upload File</Label>
              <div className="proof-sec text-center">
                <label className="label">
                  <input
                    type="file"
                    name="acFile"
                    onChange={(e) => setValue("acFile", e?.target?.files)}
                  />
                  <p>
                    <img src={Uploadicon} className="me-2 img-fluid" />
                    <span
                      className={`${
                        watch("acFile")?.[0]?.name ? "themecolor" : ""
                      }`}
                    >
                      {watch("acFile")?.[0]?.name || "Upload File (xls)"}
                    </span>
                  </p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer z-0">
          <Button type="button" className="graybutton" onClick={onClose}>
            Cancel
          </Button>
          <CustomeButton
            type="submit"
            isButtonLoading={isButtonLoading}
            className="submitbutton"
          >
            Upload
          </CustomeButton>
        </div>
      </Form>
    </Modal>
  );
};

export default BankStatementFileUplod;
