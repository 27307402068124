import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DROPDOWN_ALL_VALUE } from "../../../constant";
import {
  depositChartGet,
  useReportAndChartDepositChart,
  useReportAndChartWithdrawalChart,
  withdrawalChartGet,
} from "../../../redux/slices/reportAndChartSlice";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Custom/Loader";
import BarChart from "../../../components/Custom/Elements/Chart/BarChart";
import { dateFormatter, responseToaster } from "../../../helperFunctions";
import { Filter_Data_Transaction } from "../../../redux/slices/transactionSlice";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";
import LineChart from "../../../components/Custom/Elements/Chart/LineChart";
import { useRole } from "../../../redux/slices/authSlice";

const initialChartFilter = {
  isFilter: false,
};

const getCurrentMonthDates = () => {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  let endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const customEndDate = new Date();
  if (endOfMonth > customEndDate) {
    endOfMonth = customEndDate;
  }

  return {
    startOfMonth,
    endOfMonth,
  };
};

const ReportAndChart = () => {
  const { startOfMonth, endOfMonth } = getCurrentMonthDates();
  const { isDepositeLoading, isWithdrawalLoading } = useSelector(
    (state) => state.reportAndChart
  );
  const [selectedRange, setSelectedRange] = useState([
    startOfMonth,
    endOfMonth,
  ]);
  const { transactionFilterState, filterStateisLoading } = useSelector(
    (state) => state.transaction
  );
  const dispatch = useDispatch();
  const role = useRole("Report and Chart");

  const [chartFilter, setChartFilter] = useState({
    ...initialChartFilter,
  });
  const depositChartData = useReportAndChartDepositChart();
  const withdrawalChartData = useReportAndChartWithdrawalChart();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
      },
      title: {
        display: false,
        text: "Deposit Chart",
        align: "start",
        font: {
          size: 16,
        },
        padding: {
          bottom: 15,
          left: 10,
          top: 20,
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: (value, index) => {
            return +index % 2 === 0 ? value : null;
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const prepareclientDataOptions = () => {
    const clientData = transactionFilterState?.cilent_data?.map?.((val) => {
      const clientDataOption = {
        value: val?.merchantId,
        label: val?.merchantName,
      };
      return clientDataOption;
    });
    return clientData;
  };

  const GetDepositChartGet = async () => {
    try {
      const { isFilter, date, ...rest } = chartFilter;
      const payload = {
        filter_data: rest,
        startDate: dateFormatter(selectedRange?.[0], "start"),
        endDate: dateFormatter(selectedRange?.[1], "end", selectedRange?.[0]),
      };
      const res = await dispatch(depositChartGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };
  const GetWithdrawalChartGet = async () => {
    try {
      const { isFilter, date, ...rest } = chartFilter;
      const payload = {
        filter_data: rest,
        startDate: dateFormatter(selectedRange?.[0], "start"),
        endDate: dateFormatter(selectedRange?.[1], "end", selectedRange?.[0]),
      };
      const res = await dispatch(withdrawalChartGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  const getFilterTransactions = async () => {
    try {
      await dispatch(Filter_Data_Transaction()).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (role.includes("reportAndChart-list")) {
      GetDepositChartGet();
      GetWithdrawalChartGet();
    }
  }, [chartFilter]);

  useEffect(() => {
    getFilterTransactions();
  }, []);

  const {
    control: controlChartFilter,
    handleSubmit: handleSubmitChartFilter,
    getValues: getValuesChartFilter,
    reset,
  } = useForm({
    defaultValues: initialChartFilter,
  });

  const onSubmitChart = () => {
    const values = getValuesChartFilter();
    setChartFilter({ ...chartFilter, ...values, isFilter: true });
  };

  const handleDateChange = ([newStartDate, newEndDate]) => {
    const clonesNewSdate = newStartDate || new Date();
    const startOfMonth = new Date(
      clonesNewSdate.getFullYear(),
      clonesNewSdate.getMonth(),
      1
    );
    let endOfMonth = new Date(
      clonesNewSdate.getFullYear(),
      clonesNewSdate.getMonth() + 1,
      0
    );
    const customEndDate = new Date();
    if (endOfMonth > customEndDate) {
      endOfMonth = customEndDate;
    }
    setSelectedRange([startOfMonth, endOfMonth]);
  };

  return (
    <>
      {role.includes("reportAndChart-list") ? (
        <>
          <div>
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h4 className="main-title">
                    <b>Report and Chart</b>
                  </h4>
                </div>
              </CardHeader>

              <CardBody>
                <Form
                  className="d-flex align-items-center mt-md-0 mt-1"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmitChartFilter(onSubmitChart)}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-end inputgap">
                        <ControlledDropDown
                          name="merchantId"
                          label="Client"
                          isDefaultOption={DROPDOWN_ALL_VALUE}
                          isLoading={filterStateisLoading}
                          options={prepareclientDataOptions()}
                          control={controlChartFilter}
                        />
                        <DatePicker
                          selected={selectedRange[0]}
                          startDate={selectedRange[0]}
                          endDate={selectedRange[1]}
                          maxDate={new Date()}
                          selectsRange
                          isKeyDown={false}
                          onChange={handleDateChange}
                          showMonthYearPicker
                        />
                        <Button color="primary" type="submit">
                          Apply
                        </Button>
                        <Button
                          type="button"
                          color="danger "
                          onClick={() => {
                            reset();
                            setChartFilter({ ...initialChartFilter });
                            setSelectedRange([startOfMonth, endOfMonth]);
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>

            <Card>
              <CardHeader className="d-flex flex-md-row flex-column justify-content-md-between justify-content-start align-items-start">
                <h4 className="main-title">
                  <b>Deposit</b>
                </h4>
              </CardHeader>
              <CardBody>
                {isDepositeLoading ? (
                  <div
                    style={{
                      height: 600,
                    }}
                    className="flex-center"
                  >
                    <Loader />
                  </div>
                ) : (
                  <div className="chartcss">
                    <LineChart
                      data={depositChartData?.data}
                      options={options}
                      isLoading={isDepositeLoading}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="d-flex flex-md-row flex-column justify-content-md-between justify-content-start align-items-start">
                <h4 className="main-title">
                  <b>Withdrawal</b>
                </h4>
              </CardHeader>
              <CardBody>
                {isWithdrawalLoading ? (
                  <div
                    style={{
                      height: 600,
                    }}
                    className="flex-center"
                  >
                    <Loader />
                  </div>
                ) : (
                  <div className="chartcss">
                    <LineChart
                      data={withdrawalChartData?.data}
                      options={options}
                      isLoading={isWithdrawalLoading}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ReportAndChart;
