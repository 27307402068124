import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Form,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  CardBody,
} from "reactstrap";
import StatsHorizontal from "../../../components/widgets/stats/StatsHorizontal";
import "./Payout.scss";
import { getPayout } from "../../../redux/slices/payoutSlice";
import { useDispatch, useSelector } from "react-redux";

// Formik validation
import { useEffect } from "react";
import {
  Filter_Data_Transaction,
  PaymentMetaLabelListGet,
} from "../../../redux/slices/transactionSlice";

// Image
import TotalWithdrawal from "../../../assets/images/pages/totalWithdrawal.svg";

import { Table, TableRow } from "../../../components/Table";
import {
  DROPDOWN_ALL_VALUE,
  DROPDOWN_SEARCH_VALUE,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import { getStatusStyle } from "../../../theme";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import PayoutTableDetails from "./PayoutTableDetails";
import ConfigurationModal from "../../../components/Custom/Modals/ConfigurationModal/ConfigurationModal";
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import GenerateReportModal from "../../../components/Custom/Modals/GenerateReportModal/GenerateReportModal";
import { LIMIT } from "../../../constant";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useForm } from "react-hook-form";
import {
  payoutTypeOptions,
  searchDataOptions,
  statusDataOptions,
} from "./mock";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { stateDataOptions } from "../Transaction/mock";
import { useRole } from "../../../redux/slices/authSlice";

const Payout = () => {
  const { payoutStateisLoading, payoutState } = useSelector(
    (state) => state.payout
  );

  const { transactionFilterState, paymentMetaLabelListGetData } = useSelector(
    (state) => state.transaction
  );
  const dispatch = useDispatch();
  const role = useRole("Withdrawal");
  const roleGenerate = useRole("Generate Report");

  const initialValues = {
    filter: DROPDOWN_SEARCH_VALUE,
    search: "",
    status: DROPDOWN_ALL_VALUE,
    merchantId: DROPDOWN_ALL_VALUE,
    pgName: DROPDOWN_ALL_VALUE,
    bankId: DROPDOWN_ALL_VALUE,
    custState: DROPDOWN_ALL_VALUE,
    withdrawalType: DROPDOWN_ALL_VALUE,
    date: [null, null],
    successDate: [null, null],
    minAmount: "",
    maxAmount: "",
    page_no: 1,
    limit: LIMIT,
    isFilter: false,
  };

  const [refresh, setRefresh] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState({ ...TRA_FILTER, isToast: false });
  const [isGenerateReportModal, setIsGenerateReportModal] = useState(false);

  const [isConfigurastionModal, setIsConfigurastionModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pgAccountOptions, setPgAccountOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const onCloseIsConfigurastionModal = () => {
    setIsConfigurastionModal(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const prepareclientDataOptions = () => {
    const clientData = transactionFilterState?.cilent_data?.map((val) => {
      const clientDataOption = {
        value: val?.merchantId,
        label: val?.merchantName,
      };
      return clientDataOption;
    });
    return clientData;
  };

  const preparepgDataOptions = () => {
    const pgData = transactionFilterState?.pgpayout_data?.map((val) => {
      const pgDataOption = { value: val, label: val };
      return pgDataOption;
    });
    return pgData;
  };
  const getpgAccDataOptions = () => {
    const pgAccDataOptions = paymentMetaLabelListGetData?.data?.map((val) => {
      const pgAccDataOption = { value: val?.acId, label: val?.note };
      return pgAccDataOption;
    });
    return pgAccDataOptions;
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
      isToast: true
    });
  };

  const { control, getValues, watch, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const getFilterTransactions = async () => {
    try {
      await dispatch(Filter_Data_Transaction()).unwrap();
    } catch (err) { }
  };

  const onCloseGenerateRepostModal = () => {
    setIsGenerateReportModal(!isGenerateReportModal);
  };

  const preparePayload = (filter) => {
    const {
      isFilter,
      page_no,
      limit,
      filter: dropdown,
      search,
      date,
      successDate,
      minAmount,
      maxAmount,
      ...rest
    } = filter;

    const payload = isFilter
      ? {
        filter_data: {
          [dropdown]: search,
          startDate: dateFormatter(date?.[0], "start"),
          endDate: dateFormatter(date?.[1], "end", date?.[0]),
          successStartDate: dateFormatter(successDate?.[0], "start"),
          successEndDate: dateFormatter(
            successDate?.[1],
            "end",
            successDate?.[0]
          ),
          minAmount: minAmount || 0,
          maxAmount: maxAmount || 0,
          ...rest,
        },
        page_no,
        limit,
      }
      : {
        page_no,
        limit,
        filter_data: {
          startDate: dateFormatter(new Date(), "start"),
          endDate: dateFormatter(new Date(), "end"),
        },
      };
    return payload;
  };
  const onGetPayout = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }

      const payload = preparePayload(filter);

      const res = await dispatch(getPayout(payload)).unwrap();
      if (filter?.isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFilterTransactions();
  }, []);

  useEffect(() => {
    if (role.includes("payout-list")) {
      onGetPayout(true);
    }
  }, [filter]);

  useEffect(() => {
    if (role.includes("payout-list")) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          onGetPayout(false);
        }
      }, 9000);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh, filter]);

  const PaymentMetaLabelList = async () => {
    try {
      const values = getValues();
      let payload = {
        pgType: "payout",
        pgName: values?.pgName ? [values?.pgName] : pgAccountOptions,
        pg_id:"all"
      };
      await dispatch(PaymentMetaLabelListGet(payload)).unwrap();
    } catch (e) { }
  };
  useEffect(() => {
    setPgAccountOptions(
      preparepgDataOptions()
        ?.filter((data) => {
          return data?.label !== "All";
        })
        ?.map((data) => {
          return data?.value;
        })
    );
  }, [transactionFilterState]);

  useEffect(() => {
    if (watch("pgName") || pgAccountOptions?.length) {
      PaymentMetaLabelList();
    }
  }, [watch("pgName"), pgAccountOptions]);

  const columns = [
    {
      title: "DATE",
      name: "createdAtIst",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.createdAtIst || "-"}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Update : </span>
                  {data?.updatedAtIst || "-"}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Success : </span>
                  {data?.successAtIst || "-"}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "WITHDRAWAl DETAILS",
      name: "withdrawalId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Withdrawal ID : </span>
                  {data?.withdrawalId || "-"}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Merchant Ref ID : </span>
                  {data?.merchantRefId || "-"}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> UTR : </span>
                  {data?.bankRrn || "-"}
                </p>
              </div>

              <div className="order-singal-div">
                <p>
                  <span>Type : </span>
                  {data?.withdrawalType || "-"}
                </p>
              </div>
              {data?.bankAc ? (
                <div className="order-singal-div">
                  <p>
                    <span> A/C No : </span>
                    {data?.bankAc}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "CUSTOMER",
      name: "custId",
      // type: "ellipsis",
      Cell: (data) => {
        const { textClassName } = getStatusStyle(data?.withdrawalSts);
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> ID : </span>
                  {data?.custId || "-"}
                </p>
              </div>

              <div className="order-singal-div">
                <p className="gap">
                  <span>State : </span>
                  <b>{`${data?.custState || "-"}`}</b>
                </p>
              </div>
              <div className="order-singal-div resmeseg ">
                <p>
                  <span className={`${textClassName}`}>Response : </span>
                  {data?.pgResponseMsg || "-"}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "CLIENT ",
      name: "acHolderName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <span>
                {data?.pgName || ""}
                {data?.bankId ? ` (${data?.bankId})` : ""}
              </span>
              {data?.merchantData?.merchantName ? (
                <div className="order-singal-div">
                  <p>
                    <span> Name : </span>
                    {data?.merchantData?.merchantName}
                  </p>
                </div>
              ) : null}
              <div className="order-singal-div">
                <p>
                  <span> ID : </span>
                  {data?.merchantData?.merchantId}
                </p>
              </div>
              {data?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span> PG Label : </span>
                    {data?.pgLabel}
                  </p>
                </div>
              ) : null}
              <div className="order-singal-div">
                <p>
                  <span>PG Ref Id : </span>
                  {data?.pgRefId || "-"}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      name: "totalAmt",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Amount : </span>
                  {`${seperator(data?.withdrawalAmt)}`}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Total : </span>
                  {`${seperator(data?.totalAmt)}`}
                </p>
              </div>

              <div className="order-singal-div">
                <p>
                  <span>Fees : </span>
                  {`${seperator(data?.withdrawalFees)}`}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS",
      name: "withdrawalSts",
      Cell: (data) => {
        const { bgClassName } = getStatusStyle(data?.withdrawalSts);
        return (
          <>
            <div className="center">
              <span className={`${bgClassName}`}>{data?.withdrawalSts}</span>
            </div>
          </>
        );
      },
    },
  ];

  const shareColumns = [
    {
      title: "DATE",
      name: "createdAtIst",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.createdAtIst || "-"}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Update : </span>
                  {data?.updatedAtIst || "-"}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Success : </span>
                  {data?.successAtIst || "-"}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "WITHDRAWAl DETAILS",
      name: "withdrawalId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Withdrawal ID : </span>
                  {data?.withdrawalId || "-"}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> UTR : </span>
                  {data?.bankRrn || "-"}
                </p>
              </div>

              <div className="order-singal-div">
                <p>
                  <span>Type : </span>
                  {data?.withdrawalType || "-"}
                </p>
              </div>
              {data?.bankAc ? (
                <div className="order-singal-div">
                  <p>
                    <span> A/C No : </span>
                    {data?.bankAc}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "CLIENT ",
      name: "acHolderName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              {data?.merchantData?.merchantName ? (
                <div className="order-singal-div">
                  <p>
                    <span> Name : </span>
                    {data?.merchantData?.merchantName}
                  </p>
                </div>
              ) : null}
              <div className="order-singal-div">
                <p>
                  <span> ID : </span>
                  {data?.merchantData?.merchantId}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      name: "totalAmt",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Amount : </span>
                  {`${seperator(+data?.withdrawalAmt)}`}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  if (!role.includes("payout-list")) {
    return;
  }
  return (
    <div
      className={`payout-main-div ${payoutStateisLoading && refresh ? "opacity-25" : ""
        }`}
    >
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Withdrawal</b>
            </h4>
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <Button
                type="button"
                className={`${refresh ? "" : "filterbtn"} gap`}
                color={refresh ? "primary" : ""}
                onClick={() => setRefresh(!refresh)}
                title="Auto refresh"
              >
                {refresh ? (
                  <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                ) : (
                  <i className="fa-solid fa-arrows-rotate"></i>
                )}
                Auto Refresh
              </Button>
              {role.includes("payout-configuration") ? <Button
                color="primary"
                title="Configuration"
                onClick={() => setIsConfigurastionModal(true)}
              >
                Configuration
              </Button> : null}
              {roleGenerate.includes("generate-withdrawal-report") ?  <Button
                type="button"
                color="primary"
                title="Generate Report"
                onClick={() => onCloseGenerateRepostModal()}
              >
                Generate Report
              </Button> : null}
              <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                  id="page-header-user-dropdown"
                  tag="button"
                  title="Column Filter"
                >
                  <IoFilterOutline
                    value={{ color: "#7367F0" }}
                    fill="#7367F0"
                  />
                </DropdownToggle>
                <DropdownMenu
                  className="z-3"
                  style={{
                    whiteSpace: "nowrap",
                    padding: 20,
                    transform: "translate(-20px, 22px)",
                  }}
                >
                  <li className="z-3 text-dark">
                    <span>
                      <i className="fa-solid fa-filter"></i> Filter Column
                    </span>
                  </li>
                  <div className="border-bottom border-light mt-2">
                    {columns?.map((column) => {
                      return (
                        <div className="d-flex justify-content-between">
                          <p>{column?.title}</p>
                          <Switch
                            checked={filterColumns?.includes(column?.name)}
                            size="small"
                            onChange={(e) => {
                              const newData = [...filterColumns];
                              const index = newData?.indexOf(column?.name);
                              if (index > -1) {
                                newData.splice(index, 1);
                              } else {
                                newData.push(column?.name);
                              }
                              setFilterColumns(newData);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Form
            className="d-flex my-xl-auto right-content align-items-end button-space flex-wrap py-1 "
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledDropDown
              name="filter"
              label="Filter"
              isDefaultOption={DROPDOWN_SEARCH_VALUE}
              options={searchDataOptions}
              control={control}
            />
            <ControlledInput
              name="search"
              placeholder="Enter Search Value"
              label="Search"
              control={control}
            />
            <ControlledDropDown
              name="status"
              label="Status"
              isDefaultOption={DROPDOWN_ALL_VALUE}
              options={statusDataOptions}
              control={control}
            />
            <ControlledDropDown
              name="custState"
              label="State"
              isDefaultOption={DROPDOWN_ALL_VALUE}
              options={stateDataOptions}
              control={control}
            />
            <ControlledDropDown
              name="merchantId"
              label="Client"
              isDefaultOption={DROPDOWN_ALL_VALUE}
              options={prepareclientDataOptions()}
              control={control}
            />

            <ControlledDropDown
              name="pgName"
              label="PG"
              isDefaultOption={DROPDOWN_ALL_VALUE}
              options={preparepgDataOptions()}
              control={control}
            />
            <ControlledDropDown
              name="bankId"
              label="PG Account"
              isDefaultOption={DROPDOWN_ALL_VALUE}
              options={getpgAccDataOptions()}
              control={control}
            />

            <ControlledDropDown
              name="withdrawalType"
              label="Type"
              isDefaultOption={DROPDOWN_ALL_VALUE}
              options={payoutTypeOptions}
              control={control}
            />
            <ControlledDatePicker
              name="date"
              placeholder="Select Date"
              label="Date"
              maxDate={new Date()}
              selectsRange
              control={control}
            />
            <ControlledDatePicker
              name="successDate"
              placeholder="Select Date"
              label="Success Date"
              selectsRange
              maxDate={new Date()}
              control={control}
            />
            <ControlledInput
              name="minAmount"
              label="Min Amount"
              type="number"
              placeholder="Enter Min Amount"
              control={control}
            />
            <ControlledInput
              name="maxAmount"
              label="Max Amount"
              type="number"
              placeholder="Enter Max Amount"
              control={control}
            />
            <Button type="submit" color="primary">
              Apply
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={() => {
                reset();
                setFilter({ ...TRA_FILTER });
              }}
            >
              Clear
            </Button>
          </Form>
        </CardBody>
      </Card>
      <div className="fourbox-detail detailsec-scroll">
        <div className="detailboxMain">
          <Card className="dollar-icon-main card-margin detailboxFix">
            <CardHeader>
              <StatsHorizontal
                className="fontstyle greentext"
                color="primary"
                image={TotalWithdrawal}
                statTitle={seperator(
                  payoutState?.payout_summary?.total_payout,
                  false
                )}
                renderStats={
                  <h5 className="fw-bolder mb-0">Total Txn</h5>
                }
              />
            </CardHeader>
          </Card>
        </div>
        <div className="detailboxMain">
          <Card className="dollar-icon-main card-margin detailboxFix">
            <CardHeader>
              <StatsHorizontal
                className="fontstyle greentext"
                color="primary"
                image={TotalWithdrawal}
                statTitle={seperator(
                  payoutState?.payout_summary?.payout_amount
                )}
                renderStats={
                  <h5 className="fw-bolder mb-0">Withdrawal Amount</h5>
                }
              />
            </CardHeader>
          </Card>
        </div>
        <div className="detailboxMain">
          <Card className="dollar-icon-main card-margin detailboxFix">
            <CardHeader>
              <StatsHorizontal
                className="fontstyle greentext"
                color="primary"
                image={TotalWithdrawal}
                statTitle={seperator(
                  payoutState?.payout_summary?.manual_payout_amount
                )}
                renderStats={
                  <h5 className="fw-bolder mb-0">Withdrawal Manual Amount</h5>
                }
              />
            </CardHeader>
          </Card>
        </div>
        <div className="detailboxMain">
          <Card className="dollar-icon-main card-margin detailboxFix">
            <CardHeader>
              <StatsHorizontal
                className="fontstyle greentext"
                color="primary"
                image={TotalWithdrawal}
                statTitle={seperator(
                  payoutState?.payout_summary?.total_payout_amount
                )}
                renderStats={
                  <h5 className="fw-bolder mb-0">Total Withdrawal Amount</h5>
                }
              />
            </CardHeader>
          </Card>
        </div>
        <div className="detailboxMain">
          <Card className="dollar-icon-main card-margin detailboxFix">
            <CardHeader>
              <StatsHorizontal
                className="fontstyle greentext"
                color="primary"
                image={TotalWithdrawal}
                statTitle={seperator(
                  payoutState?.payout_summary?.total_payout_fees
                )}
                renderStats={<h5 className="fw-bolder mb-0">Total Fees</h5>}
              />
            </CardHeader>
          </Card>
        </div>
      </div>
      <Card>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading && !refresh}
          data={payoutState?.data || []}
          isData={!!payoutState?.data?.length}
          count={payoutState?.total_item || 0}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
          paginationOptions={TRA_PAGINATION_OPTIONS}
        >
          {payoutState?.data?.map((item) => {
            return (
              <TableRow
                tdClassName="position-relative"
                columns={columns}
                filterColumns={filterColumns}
                item={item}
                iWantShare={true}
                shareColumns={shareColumns}
              >
                <PayoutTableDetails
                  item={item}
                  // onGet={onGetPayout}
                  onGet={() => setFilter({ ...filter, isFilter: true, isToast: false })}
                />
              </TableRow>
            );
          })}
        </Table>
      </Card>

      <ConfigurationModal
        isConfigurastionModal={isConfigurastionModal}
        onCloseIsConfigurastionModal={onCloseIsConfigurastionModal}
        onGet={() => setFilter({ ...filter })}
      />
      <GenerateReportModal
        isGenerateReportModal={isGenerateReportModal}
        onCloseGenerateRepostModal={onCloseGenerateRepostModal}
        payload={preparePayload(filter)}
      />
    </div>
  );
};
export default Payout;
