import { LOGIN, LOGIN_AGAIN, LOGOUT } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const logIn = (payload) => {
  return apiInstance.post(LOGIN, payload);
};

export const logInAgain = (payload) => {
  return apiInstance.post(LOGIN_AGAIN, payload);
};

export const logOut = (payload) => {
  return apiInstance.post(LOGOUT, payload);
};
