import UpdateManualBankTraModal from "../../../components/Custom/Modals/UpdateManualBankTraModal/UpdateManualBankTraModal";
import SetInitializedModal from "../../../components/Custom/Modals/SetInitializedModal/SetInitializedModal";
import PayoutWebhookModal from "../../../components/Custom/Modals/PayoutWebhookModal/PayoutWebhookModal";
import CancelPayoutModal from "../../../components/Custom/Modals/CancelPayoutModal/CancelPayoutModal";
import React, { useState } from "react";
import { seperator } from "../../../helperFunctions";
import { getStatusStyle } from "../../../theme";
import { Button } from "reactstrap";
import { useRole } from "../../../redux/slices/authSlice";

const PayoutTableDetails = (props) => {
  const { item, onGet } = props;
  const [payloadPayoutData, setPayloadPayoutData] = useState(false);
  const [initializedPayoutModal, setInitializedPayoutModal] = useState(false);
  const [resendWebhookIdModal, setResendWebhookIdModal] = useState(false);
  const [isSetPayoutModal, setIsSetPayoutModal] = useState(false);
  const role = useRole("Withdrawal");
  const { bgClassName } = getStatusStyle(item?.withdrawalSts);

  const onToggleCancelPayoutModal = (id) => {
    setIsSetPayoutModal(isSetPayoutModal ? false : id);
  };

  const onCloseResendWebhookModal = (id) => {
    setResendWebhookIdModal(id);
  };

  const onToggleSetPayoutModal = (id) => {
    setInitializedPayoutModal(initializedPayoutModal ? false : id);
  };

  return (
    <>
      <div className="d-flex tabmain"></div>
      <div className="mainbox">
        <div className="d-flex gap-1">
          <div className="box-style">
            <h5>
              <span>Bank Details</span>
            </h5>
            <div className="other-sec">
              <p>
                <b> A/c Name : </b> {item?.acHolderName || ""}
              </p>
              <p>
                <b> A/c No : </b> {item?.bankAc || ""}
              </p>
              <p>
                <b> Bank : </b> {item?.bankName || ""}
              </p>
              <p>
                <b> IFSC : </b> {item?.ifscCode || ""}
              </p>
              <p>
                <b>Customer City :</b> {item?.custCity}
              </p>
            </div>
          </div>
          <div className="box-style">
            <h5>
              <span> PG Details</span>
            </h5>
            <div className="other-sec">
              <p>
                <b>Label :</b>
                {item?.pgLabel || "-"}
              </p>
              <p>
                <b>Bank :</b>
                {item?.pgName || ""}
              </p>
              <p>
                <b>Meta Id :</b>
                {item?.bankId || ""}
              </p>
              <p>
                <b>Batch Id :</b>
                {item?.manualPayBatchId || ""}
              </p>
            </div>
          </div>

          <div className="box-style">
            <h5>
              <span> Other Detail</span>
            </h5>

            <div className="other-sec">
              <p>
                <b> Temp UTR : </b> {item?.tempBankRrn || ""}
              </p>
              <p>
                <b> PG Ref Id : </b> {item?.pgRefId || ""}
              </p>
              <p>
                <b> Assoc Fees : </b> {seperator(item?.associateFees)}
              </p>
              <p>
                <b> Wirhdrawal By : </b> {item?.withdrawalBy || ""}
              </p>
              <p>
                <b> Webhook : </b> {item?.isNotifyCalled || ""}
              </p>
            </div>
          </div>
        </div>

        <div className="amount-sec">
          <div className="amount-sub">
            <div>
              <p>Total Amount</p>
              <h4>{`${seperator(+item?.totalAmt)}`}</h4>
            </div>
            <div>
              <p>Withdrawal ID</p>
              <h6>{item?.withdrawalId}</h6>
            </div>
            <div>
              <p>UTR</p>
              <h6> {item?.bankRrn || "-"}</h6>
            </div>
            <div>
              <p>Status</p>
              <span className={`${bgClassName}`}>{item?.withdrawalSts}</span>
            </div>
            <div className="d-flex gap-1">
              {item?.withdrawalSts !== "Failed" &&
                role.includes("payout-status-update") && (
                  <Button
                    className="tablebtn btn-smallsize"
                    onClick={() => {
                      setPayloadPayoutData({
                        withdrawalId: item?.withdrawalId,
                        withdrawalSts: null,
                        utr: item?.bankRrn,
                      });
                    }}
                  >
                    Update
                  </Button>
                )}
              {(item?.withdrawalSts === "Pending" ||
                item?.withdrawalSts === "OnHold" ||
                item?.withdrawalSts === "NotFound" ||
                item?.withdrawalSts === "Processing" ||
                item?.withdrawalSts === "RECHECK" ||
                item?.withdrawalSts === "FROZEN") &&
              role.includes("payout-reset-initialized") ? (
                <Button
                  className="tablebtn btn-smallsize"
                  onClick={() => {
                    onToggleSetPayoutModal(item?.withdrawalId);
                  }}
                >
                  Set Initialized
                </Button>
              ) : null}
              {item?.withdrawalSts !== "Failed" &&
              role.includes("payout-failed") ? (
                <Button
                  className="tablebtn btn-smallsize"
                  onClick={() => {
                    onToggleCancelPayoutModal(item?.withdrawalId);
                  }}
                >
                  Set Withdraw To Failed
                </Button>
              ) : null}
              {(item?.withdrawalSts === "Success" ||
                item?.withdrawalSts === "Failed") &&
              role.includes("payout-resend-webhook") ? (
                <Button
                  className="tablebtn btn-smallsize"
                  onClick={() => {
                    onCloseResendWebhookModal(item?.withdrawalId);
                  }}
                >
                  Resend Webhook
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <UpdateManualBankTraModal
        isOpen={payloadPayoutData}
        onHide={() => setPayloadPayoutData(false)}
        onGet={onGet}
      />
      <CancelPayoutModal
        isOpen={isSetPayoutModal}
        onHide={onToggleCancelPayoutModal}
        onGet={onGet}
      />
      <PayoutWebhookModal
        onHide={onCloseResendWebhookModal}
        isOpen={resendWebhookIdModal}
        onGet={onGet}
      />
      <SetInitializedModal
        isOpen={initializedPayoutModal}
        onHide={onToggleSetPayoutModal}
        onGet={onGet}
      />
    </>
  );
};

export default PayoutTableDetails;
