import { ADD_USERS, DELETE_USERS, GET_USERS, RESET_USER_PASSWORD, UPDATE_USERS } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getUsers = (payload) => {
  return apiInstance.post(GET_USERS, payload);
};
export const addUsers = (payload) => {
  return apiInstance.post(ADD_USERS, payload);
};
export const updateUsers = (payload) => {
  return apiInstance.post(UPDATE_USERS, payload);
};
export const deletUsers = (payload) => {
  return apiInstance.post(DELETE_USERS, payload);
};
export const resetUserPassword = (payload) => {
  return apiInstance.post(RESET_USER_PASSWORD, payload);
};