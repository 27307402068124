import { useEffect, useLayoutEffect, useState } from "react";
import HorizontalLayout from "./Layouts/HorizontalLayout/HorizontalLayout";
import VerticalLayout from "./Layouts/VerticalLayout/VerticalLayout";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSidebar } from "../redux/slices/authSlice";
import Header from "./Layouts/VerticalLayout/Header/Header";
import { getLocalData, prepareIsHorizontalNavBar, setStyles } from "../helperFunctions";
import Loader from "./Custom/Loader";
import ScrollToTop from "./Layouts/ScrollTotop/ScrollToTop";
import LoginAgainModal from "./Custom/Modals/LoginAgainModal/LoginAgainModal";

const color = process.env.REACT_APP_COLOR;

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sidebar = useSidebar();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let newData = [];
    const prepareData = (data) => {
      Object.entries(data || {})?.forEach(([key, val]) => {
        if (Array.isArray(val)) {
          newData = [...newData, ...val];
        } else if (val instanceof Object) {
          prepareData(val);
        }
      });
    };
    prepareData(sidebar);
    const currentRoute = location?.pathname?.split("/")?.at(-1);
    const currentPage = newData?.find((item) =>
      item?.route?.includes(currentRoute)
    );
    if (currentPage) {
      window.document.title =
        currentPage?.group === "PG"
          ? "PG " + currentPage?.name
          : currentPage?.name;
    }
  }, [navigate, sidebar]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    if (color) {
      setIsLoading(true);
      setStyles("--chatlook-darkblue--", color);
    }
    // setTimeout(() => {
    setIsLoading(false);
    // }, 3000);
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="w-100 vh-100  d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
    <div className="position-relative">
      {prepareIsHorizontalNavBar(windowWidth) ? (
        <HorizontalLayout />
      ) : (
        <VerticalLayout
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      )}
      <div className="page-main">
        {!prepareIsHorizontalNavBar(windowWidth) ? (
          <Header
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
          />
        ) : null}
        <div
          className="jumps-prevent"
          style={{
            paddingTop:
              windowWidth >= 1231
                ? "64px"
                : prepareIsHorizontalNavBar(windowWidth)
                ? "120px"
                : "10px",
          }}
        ></div>
        <div
          className="main-content app-content mt-0"
          style={{ paddingBottom: "35px" }}
        >
          <div className="side-app">
            <div className="main-container">
              <Outlet />
              <ScrollToTop />
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoginAgainModal />
    </>
  );
};

export default App;
