import { Button, Card, CardHeader } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import AddUserModal from "./AddUserModal";
import { useUsers, usersGet } from "../../../redux/slices/usersSlice";
import UserDeleteModal from "./UserDeleteModal";
import UserResetPassword from "./UsersResetPassword";
import { rolessGet } from "../../../redux/slices/rolesSlice";
import { useRole } from "../../../redux/slices/authSlice";

const Users = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);
  const [userResetPassword, setUserResetPassword] = useState(false);
  const role = useRole("Role");
  const role_manager = useRole("Role Manager");

  const data = useUsers();
  const dispatch = useDispatch();

  const columns = [
    {
      title: "DATE",
      name: "createdAt",
    },
    {
      title: "USERNAME",
      name: "userName",
    },
    {
      title: "ACTIONS",
      name: "actions",
      Cell: (data) => {
        return (
          <>
            <div className="button-space d-flex">
              {role_manager.includes("update-sub-user") ? (
                <Button
                  title="Edit"
                  color="primary"
                  onClick={() => {
                    setIsOpenModal(data);
                  }}
                >
                  Edit
                </Button>
              ) : null}
              {role_manager.includes("update-sub-user") ? (
                <Button
                  title="Reset Password"
                  color="primary"
                  onClick={() => setUserResetPassword(data?.id)}
                >
                  Reset Password
                </Button>
              ) : null}
              {role_manager.includes("delete-sub-user") ? (
                <Button
                  title="Delete User"
                  color="danger"
                  onClick={() => setIsDeleteOpenModal(data?.id)}
                >
                  Delete User
                </Button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  const onGetData = async () => {
    try {
      setIsLoading(true);
      const { isFilter, ...payload } = filter;
      const res = await dispatch(usersGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onGetRolesData = async () => {
    try {
      const { isFilter, ...payload } = FILTER;
      await dispatch(rolessGet(payload)).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (role_manager.includes("roleManager-list")) {
      onGetData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("role-list")) {
      onGetRolesData();
    }
  }, []);

  if (!role_manager.includes("roleManager-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Users</b>
            </h4>
            <div
              className="d-flex align-items-center justify-content-around"
              style={{ gap: "10px" }}
            >
              {role_manager.includes("add-sub-user") ? (
                <Button
                  title="Add User"
                  color="primary"
                  onClick={() => setIsOpenModal(true)}
                >
                  Add User
                </Button>
              ) : null}
            </div>
          </div>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={data?.data}
          isData={!!data?.data?.length}
          count={data?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.data?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>

      <AddUserModal
        isOpen={isOpenModal}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setIsOpenModal(false)}
      />
      <UserDeleteModal
        isOpen={isDeleteOpenModal}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setIsDeleteOpenModal(false)}
      />
      <UserResetPassword
        userResetPassword={userResetPassword}
        onCloseUserResetPassword={() => setUserResetPassword(false)}
        id={userResetPassword}
      />
    </div>
  );
};

export default Users;
