import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { logIn, logInAgain, logOut } from "../services/authServices";
import { getLocalData, setLocalData } from "../../helperFunctions";
import { useAppSelector } from "../../utility/hooks";

const initialState = {
  user: getLocalData("token"),
  isLoading: false,
  sidebar: {},
};
export const login = createAsyncThunk("auth/login", async (payload) => {
  return await logIn(payload);
});

export const loginAgain = createAsyncThunk("auth/loginAgain", async (payload) => {
  return await logInAgain(payload);
});

export const Logout = createAsyncThunk("auth/logout", async (payload) => {
  return await logOut(payload);
});

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = !!payload;
    },
    setSidebar: (state, { payload }) => {
      state.sidebar = payload || {};
    },
    setUser: (state, { payload }) => {
      state.user = payload || {};
    },
    clearAuth: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
      state.sidebar = action.payload?.SiderBar || {};
    });
    builder.addCase(loginAgain.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        token: action.payload?.token
      }
    });
    builder.addCase(loginAgain.rejected, (state, action) => {
      state.user = {}
    });
    builder.addCase(Logout.fulfilled, (state, action) => {
      state.user = {};
    });
  },
});
export default authSlice.reducer;
export const { setIsLoading, setSidebar, clearAuth, setUser } = authSlice.actions;

export const selectUser = (state) => {
  return state.auth.user;
};
export const useUser = () => {
  const user = useSelector(selectUser);
  setLocalData("token", user, {});
  return useMemo(() => ({ user }), [user]);
};

export const selectSidebar = (state) => {
  return state.auth.sidebar;
};
export const useSidebar = () => {
  const sidebar = useSelector(selectSidebar);
  return useMemo(() => sidebar, [sidebar]);
};

export const selectIsLoading = (state) => {
  return state.auth.isLoading;
};
export const useIsLoading = () => {
  const isLoading = useSelector(selectIsLoading);
  return useMemo(() => isLoading, [isLoading]);
};

export const selectRole = (state) => state.auth.user;
export const useRole = (page = "") => {
  const user = useAppSelector(selectRole);
  const role = user?.permission?.[page] || [];
  return useMemo(() => role, [role]);
};