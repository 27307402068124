export const searchDataOptions = [
    { value: "bankRrn", label: "Bank RRN" },
    { value: "browserId", label: "Browser ID" },
    { value: "custCity", label: "Customer City" },
    { value: "custIp", label: "Customer IP" },
    { value: "custEmail", label: "Customer Email" },
    { value: "custMobile", label: "Customer Mobile" },
    { value: "custId", label: "Customer Id" },
    { value: "custName", label: "Customer Name" },
    { value: "merchantOrderId", label: "Merchant Order Id" },
    { value: "pgOrderId", label: "PG Order Id" },
    { value: "pgRefId", label: "PG Ref Id" },
    { value: "paymentDetail", label: "UPI ID" },
    { value: "paymentAmt", label: "Payment Amount" },
    { value: "tempBankUtr", label: "Temp UTR" },
    { value: "bankUpiId", label: "PG Bank UPI ID" },
  ];

export const statusDataOptions = [
    { value: "Success", label: "Success" },
    { value: "Processing", label: "Processing" },
    { value: "Initialized", label: "Initialized" },
    { value: "Pending", label: "Pending" },
    { value: "Failed", label: "Failed" },
    { value: "BankDown", label: "BankDown" },
    { value: "Open", label: "Open" },
    { value: "Not Attempted", label: "Not Attempted" },
  ];

export const stateDataOptions = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Delhi", label: "Delhi" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Rangpur Division", label: "Rangpur Division" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
export const blockUserDataOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];
export const showPageDataOptions = [
    { value: "blank page", label: "Blank Page" },
    { value: "example.com", label: "Example.com" },
    { value: "epacificspree.com", label: "epacificspree.com" },
    { value: "odd_amount", label: "Odd Amount" },
  ];
export const MethodDataOptions = [
    { value: "UPI", label: "UPI" },
    { value: "GooglePay", label: "Google Pay" },
    { value: "PayTM", label: "Pay TM" },
    { value: "PhonePe", label: "Phone Pe" },
    { value: "Manual", label: "Manual" },
  ];