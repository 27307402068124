import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableRow } from "../../../components/Table";
import MetriwesFileUpload from "../../../components/Custom/Modals/Metriwes/MetriwesFileUpload";
import {
  gatewayStatementGet,
  useGatewayStatementState,
} from "../../../redux/slices/metriwsSlice";
import { onFixed, seperator } from "../../../helperFunctions";
import MetriwesParseModal from "./MetriwesParseModal";
import { TRA_FILTER } from "../../../constant";
import { Box } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useRole } from "../../../redux/slices/authSlice";

const Metriwes = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(TRA_FILTER);
  const role = useRole("Gateway Statement");

  const [parseData, setparseData] = useState(null);
  const gatewayStatementState = useGatewayStatementState();
  const { metriwsState, metriwsIsLoading, gatewayIsLoading } = useSelector(
    (state) => state.metriws
  );
  const [filterColumns, setFilterColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 4,
      backgroundColor: theme.palette.mode === "light" ? "#76bdff" : "#308fe8",
    },
  }));

  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "FILE INFO",
      name: "fileName",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  <span>Create : </span>
                  {data?.created_at || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Update : </span>
                  {data?.updated_at || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Name : </span>
                  {data?.fileName || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    // {
    //   title: "IS GET",
    //   name: "isGet",
    //   Cell: (data) => {
    //     return (
    //       <>
    //         <div className="cell-order">
    //           <div className="order-singal-div">
    //             <p>{data?.isGet ? " Yes" : "No"}</p>
    //           </div>
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "UTR COUNT	",
      name: "totalCnt",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  <span>Total Count : </span>
                  {`${seperator(data?.totalCount, false)}`}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Parse Data : </span>
                  {seperator(+data?.parsedData?.length, false) || 0}
                </p>
              </div>
              {/*  <div className="order-singal-div">
                <p>
                  <span>Parse Data : </span>
                  {seperator(
                    +data?.totalCount - +data?.parsedData?.length,
                    false
                  ) || 0}
                </p>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      title: "PROGRESS",
      name: "Development",
      Cell: (data) => {

        // const todata = (+data?.progress / +data?.totalCount) * 100 || 0;
        function calculateProgressPercentage(data) {
          if (data?.totalCount == 0) {
            return 0;
          }
          const completedCount = data?.totalCount - data?.pendingCount;
          const percentage = (completedCount / data?.totalCount) * 100;
          return percentage;
        }
        const todata = calculateProgressPercentage(data);
        // const todata = (+data?.totalCount - +data?.pendingCount / +data?.totalCount) * 100 || 0;
        const progressPer = todata > 100 ? 100 : todata;
        return (
          <>
            <div className="position-relative">
              <Box sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={progressPer}
                />
              </Box>
              <div className="progress-position text-center">
                <span>{onFixed(progressPer)}%</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "ACTION",
      Cell: (data) => {
        return (
          <>
            <div className="d-flex flex-column gap-50">
              {!!data?.parsedData?.length ? (
                <Button
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => setparseData(data)}
                >
                  Show Parsed Record
                </Button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const GatewayStatementGet = async () => {
    try {
      const { isFilter, page_no, limit, ...rest } = filter;
      const payload = isFilter
        ? { filter_data: {}, page_no, limit }
        : {
          page_no,
          limit,
        };
      await dispatch(gatewayStatementGet(payload)).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    if (role.includes("gatewayStatement-list")) {
      GatewayStatementGet();
    }
  }, [filter]);

  if (!role.includes("gatewayStatement-list")) {
    return;
  }

  return (
    <>
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Gateway Statement</b>
            </h4>
            {role.includes("add-gateway-statement-list") ? (
              <Button
                color="primary"
                title="Bank Statement"
                onClick={() => setIsModalOpen(true)}
              >
                Add Gateway Statement
              </Button>
            ) : null}
          </div>
          <div
            className="d-flex my-xl-auto right-content align-items-end button-space"
            style={{ flexWrap: "wrap" }}
          ></div>
        </CardHeader>
        <Table
          columns={columns}
          isLoading={gatewayIsLoading}
          data={gatewayStatementState?.data || []}
          isData={!!gatewayStatementState?.data?.length}
          filterColumns={filterColumns}
          count={gatewayStatementState?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {gatewayStatementState?.data?.map((item) => {
            return (
              <TableRow
                columns={columns}
                item={item}
                filterColumns={filterColumns}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </Card>
      <MetriwesFileUpload
        setIsButtonLoading={setIsLoading}
        isButtonLoading={isLoading}
        isModalOpen={isModalOpen}
        onGet={() => GatewayStatementGet()}
        onCloseModal={() => {
          setIsModalOpen(false);
        }}
      />
      <MetriwesParseModal data={parseData} onClose={() => setparseData(null)} />
    </>
  );
};

export default Metriwes;
