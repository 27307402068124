import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Button } from "reactstrap";
import { responseToaster, seperator } from "../../helperFunctions";
import InputField from "../../components/Custom/Forms/InputField/InputField";
import { useLocation } from "react-router-dom";
import { pgUpdateBalance, pgUpdateProductInfo } from "../../redux/slices/pgSlice";
import { getPgIdFromPgName } from "./Pg";
import { useUser } from "../../redux/slices/authSlice";

const PgMerchantId = (props) => {
  const {
    updateBalanceDetails,
    setUpdateBalanceDetails,
    data,
    columns,
    onGet,
    setIsLoading,
  } = props;

  const dispatch = useDispatch();
  const locations = useLocation()?.pathname?.split("/");

  const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };
  const isPayin = params?.pgType === "payin";
  const isPayout = params?.pgType === "payout";
  const { user } = useUser();

  const [isInput, setIsInput] = useState(false);
  const [value, setValue] = useState("");

  const onUpdate = async () => {
    try {
      setIsLoading(true);
      const payload = {
        payload: { pk: data?.account_id, value, pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName) },
        params,
      };
      const resMin = await dispatch(pgUpdateProductInfo(payload)).unwrap();
      responseToaster(resMin);
      onGet();
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onBalanceUpdate = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const payload = {
        payload: { pk: data?.account_id, value: updateBalanceDetails?.value, pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName) },
        params,
      };
      const res = await dispatch(pgUpdateBalance(payload)).unwrap();
      responseToaster(res);
      onGet();
      setUpdateBalanceDetails({})
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
        onClick={(e) => e.stopPropagation()}
      >
        {isPayin || isPayout ? (
          <div className="order-singal-div">
            <p>
              <span>MID : </span>
              {data?.merchant_id || ""}
            </p>
          </div>
        ) : null}
        {
          /* data?.available_balance &&  */ isPayout ? (
            <div className="order-singal-div">
              <div onClick={(e) => e.stopPropagation()}>
                <span>Balance : </span>
                {updateBalanceDetails.input === "balance" &&
                  (updateBalanceDetails?.id === data?.id ||
                    updateBalanceDetails?.id === data?.account_id) ? (
                  <form
                    className="d-flex tableinput"
                    onSubmit={onBalanceUpdate}
                  >
                    <InputField
                      autoFocus
                      value={updateBalanceDetails?.value}
                      placeholder="Enter Balance"
                      onChange={(e) =>
                        setUpdateBalanceDetails({
                          ...updateBalanceDetails,
                          value: e.target.value,
                        })
                      }
                    />
                    <Button
                      type="submit"
                      className="btn-smallsize"
                      color="primary"
                    >
                      <i className="fa-solid fa-check"></i>
                    </Button>
                    <Button
                      color="danger"
                      className="btn-smallsize"
                      onClick={() => setUpdateBalanceDetails({})}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </Button>
                  </form>
                ) : (
                  <span
                    className="cursor-pointer"
                    style={{
                      color: columns?.includes("available_balance")
                        ? "var(--chatlook-darkblue--)"
                        : "#000000cb",
                    }}
                    onClick={() => {
                      if (columns?.includes("available_balance")) {
                        setUpdateBalanceDetails({
                          id: data?.id || data?.account_id,
                          input: "balance",
                          value: data?.available_balance,
                        });
                      }
                    }}
                  >
                    {`${seperator(data?.available_balance)}`}
                  </span>
                )}
              </div>
            </div>
          ) : /*   <div className="order-singal-div">
              <p>
                <span>Balance : </span>
                {data?.available_balance}
              </p>
            </div> */
            null
        }
        {data?.last_check_balance_at_ist && isPayout ? (
          <div className="order-singal-div">
            <p>
              <span>Last Check : </span>
              {data?.last_check_balance_at_ist || "-"}
            </p>
          </div>
        ) : null}
        {data?.product_info && isPayin ? (
          <div className="order-singal-div">
            <div>
              <span>Product Info : </span>
              {isInput ? (
                <div className="d-flex gap-1">
                  <InputField
                    value={value}
                    placeholder="Enter Product Info"
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <Button color="primary" onClick={onUpdate}>
                    <i className="fa-solid fa-check"></i>
                  </Button>
                  <Button color="danger" onClick={() => setIsInput(false)}>
                    <i className="fa-solid fa-xmark"></i>
                  </Button>
                </div>
              ) : (
                <span
                  className="cursor-pointer"
                  style={{
                    color: columns?.includes("is_active")
                      ? "var(--chatlook-darkblue--)"
                      : "",
                  }}
                  onClick={() => {
                    if (columns?.includes("is_active")) {
                      setIsInput(true);
                      setValue(data?.product_info);
                    }
                  }}
                >
                  {data?.product_info || "Not Set"}
                </span>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PgMerchantId;
