import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addPg,
  emptyBankPg,
  getPg,
  testPaymentPg,
  updateAutoLoginPg,
  updateBalancePg,
  updateBankPg,
  updateBankUser,
  updateMaxLimitPg,
  updateMinLimitPg,
  updatePaymentMaxCountLimitPg,
  updatePaymentMethodPg,
  updatePaymentTurnOverPg,
  updateProductInfoPg,
  updateStatusPg,
  updateUpiPg,
} from "../services/pgServices";

const initialState = {
  data: {},
  isLoading: true,
};
export const pgGet = createAsyncThunk("/pgGet", async (payload) => {
  return await getPg(payload?.params, payload?.payload);
});
export const pgUpdateStatus = createAsyncThunk(
  "/pgUpdateStatus",
  async (payload) => {
    return await updateStatusPg(payload?.params, payload?.payload);
  }
);
export const pgUpdateMinLimit = createAsyncThunk(
  "/pgUpdateMinLimit",
  async (payload) => {
    return await updateMinLimitPg(payload?.params, payload?.payload);
  }
);
export const pgUpdateMaxLimit = createAsyncThunk(
  "/pgUpdateMaxLimit",
  async (payload) => {
    return await updateMaxLimitPg(payload?.params, payload?.payload);
  }
);
export const pgUpdateProductInfo = createAsyncThunk(
  "/pgUpdateProductInfo",
  async (payload) => {
    return await updateProductInfoPg(payload?.params, payload?.payload);
  }
);
export const pgUpdateBalance = createAsyncThunk(
  "/pgUpdateBalance",
  async (payload) => {
    return await updateBalancePg(payload?.params, payload?.payload);
  }
);
export const pgUpdatePaymentMethod = createAsyncThunk(
  "/pgUpdatePaymentMethod",
  async (payload) => {
    return await updatePaymentMethodPg(payload?.params, payload?.payload);
  }
);
export const pgUpdatePaymentTurnOver = createAsyncThunk(
  "/pgUpdatePaymentTurnOver",
  async (payload) => {
    return await updatePaymentTurnOverPg(payload?.params, payload?.payload);
  }
);
export const pgUpdatePaymentMaxCountLimit = createAsyncThunk(
  "/pgUpdatePaymentMaxCountLimit",
  async (payload) => {
    return await updatePaymentMaxCountLimitPg(
      payload?.params,
      payload?.payload
    );
  }
);
export const pgUpdateUpi = createAsyncThunk("/pgUpdateUpi", async (payload) => {
  return await updateUpiPg(payload?.params, payload?.payload);
});
export const pgUpdateAutoLogin = createAsyncThunk(
  "/pgUpdateAutoLogin",
  async (payload) => {
    return await updateAutoLoginPg(payload?.params, payload?.payload);
  }
);
export const pgAdd = createAsyncThunk("/pgAdd", async (payload) => {
  return await addPg(payload);
});

export const pgTestPayment = createAsyncThunk(
  "/testPaymentPg",
  async (payload) => {
    return await testPaymentPg(payload?.params, payload?.payload);
  }
);

export const pgEmptyBank = createAsyncThunk("/pgEmptyBank", async (payload) => {
  return await emptyBankPg(payload);
});

export const pgUpdateBank = createAsyncThunk("/pgUpdateBank", async (payload) => {
  return await updateBankPg(payload);
});

export const pgUpdateUser = createAsyncThunk("/pgUpdateUser", async (payload) => {
  return await updateBankUser(payload);
});

const pgSlice = createSlice({
  name: "pgSlice",
  initialState,
  reducers: {
    clearPg: () => initialState,
  },
});
export default pgSlice.reducer;
export const { clearPg } = pgSlice.actions;
