import React, { useState } from "react";
import { Button, Form, Modal, Row } from "reactstrap";
import { capitalCase, capitalCaseLabel, responseToaster } from "../../helperFunctions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { pgAdd } from "../../redux/slices/pgSlice";
import { useEffect } from "react";
import { Bank_Available } from "../../redux/slices/bankTransactionSlice";
import CustomeButton from "../../components/Custom/Forms/CustomeButton/CustomeButton";
import { NUMBER_TYPE_FIELDS, UPPERCASE_LABEL } from "../../constant";
import ControlledDropDown from "../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../components/Custom/Forms/Controller/ControlledInput";
import { useForm } from "react-hook-form";
import { getBanklistOptions } from "./mock";

const AddPgModal = (props) => {
  const { onHide, isOpen, config, onGet } = props;
  const dispatch = useDispatch();
  const locations = useLocation()?.pathname?.split("/");
  const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {};

  const nameChanges = {
    payout: "withdrawal",
    payin: "deposit",
  };

  const getName = () =>
    `${capitalCase(params?.pgName)} ${capitalCase(
      nameChanges?.[params?.pgType?.toLowerCase()]
    )} Bank`;

  const bank_Available = async () => {
    try {
      const payload = {};
      await dispatch(Bank_Available(payload)).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    bank_Available();
  }, []);

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const payload = {
        formData: { ...values}
      };
      const res = await dispatch(pgAdd(payload)).unwrap();
      if (res) {
        responseToaster(res);
      }
      onClose();
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const getOptions = (column) => {
    let options = [];
    if (column === "bank_name") {
      options = getBanklistOptions?.sort((a, b) => a?.label.localeCompare(b?.label))?.map((val) => ({
        value: val?.label,
        label: val?.label,
      }));
    } else if (config?.proxyList?.length) {
      if (column === "proxy_id") {
        options = [
          // { value: "", label: "Select" },
          ...config?.proxyList?.map((item) => ({
            value: item?.id,
            label: item?.label_name,
          })),
        ];
      } else if (column === "is_seamless") {
        options = [
          // { value: "", label: "Select" },
          {
            value: "1",
            label: "Yes",
          },
          {
            value: "0",
            label: "No",
          },
        ];
      }
    }
    return [...options];
  };

  const onClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      toggle={onClose}
      centered={true}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h4 className="modal-title mt-0">{"Add " + getName()}</h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <Row>
            {config?.columns?.map((item) => {
              if (
                item === "proxy_id" ||
                item === "is_seamless" ||
                item === "bank_name"
              ) {
                return (
                  <>
                    <div className="select-div">
                      <ControlledDropDown
                        name={item}
                        label={capitalCaseLabel(item)}
                        options={getOptions(item)}
                        placeholder={`Select ${capitalCaseLabel(item)}`}
                        control={control}
                      />
                    </div>
                  </>
                );
              }
              return (
                <>
                  <div className="select-div">
                    <ControlledInput
                      name={item}
                      label={Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}
                      placeholder={`Enter ${Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}`}
                      type={NUMBER_TYPE_FIELDS.includes(item) ? "number" : "text"}
                      control={control}
                    />
                  </div>
                </>
              );
            })}
          </Row>
        </div>
        <div className="modal-footer z-0">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddPgModal;
